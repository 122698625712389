import React from "react";
import { RouteComponentProps } from "react-router";
import { Route, Switch, withRouter } from "react-router-dom";
import Afspraak from "./Afspraak";
import App from "./App";
import Contacts from "./Contacts";
import Praktijk from "./Praktijk";
import Specialisaties from "./Specialisaties";
import AlgemeneInformatie from "./AlgemeneInformatie";
import Afspraken from "./Afspraken";

// These props are provided by the router
interface PathProps {
  history: any;
  location: any;
  match: any;
}

class Container extends React.Component<RouteComponentProps<PathProps>> {
  public render() {
    return (
      <Switch>
        <Route exact={true} path="/" component={App} />
        <Route path="/afspraken" component={Afspraken} />
        <Route path="/afspraak" component={Afspraak} />
        <Route path="/algemeneinformatie" component={AlgemeneInformatie} />
        <Route path="/contact" component={Contacts} />
        <Route path="/specialisaties" component={Specialisaties} />
        <Route path="/praktijk" component={Praktijk} />
        <Route path="/**" component={App} />
      </Switch>
    );
  }
}

export default withRouter(Container);
