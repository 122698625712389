import {
  AppBar,
  Container,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { Call, MailOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#4b0340",
      height: "2.2rem",
      [theme.breakpoints.up("md")]: {
        paddingLeft: "50px",
        paddingRight: "50px",
      },
    },
    toolbar: {
      maxHeight: "2.2rem",
      padding: 0,
      minHeight: "2.2rem",
    },
    navDisplayFlex: {
      display: `flex`,
      height: "2.2rem",
      padding: 0,
      margin: 0,
    },
    font: {
      color: "white",
      fontWeight: 600,
      [theme.breakpoints.down("xs")]: {
        fontSize: 10,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 12,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 12,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 12,
      },
    },
    icons: {
      color: "rgba(255, 152, 0, 1)",
      [theme.breakpoints.down("xs")]: {
        fontSize: 15,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 30,
      },
    },
  })
);

const TopBar = () => {
  const classes = useStyles();
  return (
    <AppBar className={classes.root} position="static">
      <Toolbar className={classes.toolbar}>
        <Container maxWidth="xl" className={classes.navDisplayFlex}>
          <Grid
            container
            xs={12}
            justifyContent="center"
            style={{ flexWrap: "nowrap" }}
          >
            <Tooltip disableFocusListener title="Phone">
              <IconButton href="tel:+31618223861" className={classes.font}>
                <Call className={classes.icons} />
                0618223861
              </IconButton>
            </Tooltip>
            <Tooltip disableFocusListener title="Email">
              <IconButton
                href="mailto:info@boostfysiotherapie.nl?Subject=Afspraak%20annulering/wijziging"
                className={classes.font}
              >
                <MailOutlined className={classes.icons} />
                info@boostfysiotherapie.nl
              </IconButton>
            </Tooltip>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
export default TopBar;
