import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    accordion: {},
    heading: {
      fontSize: theme.typography.pxToRem(16),
      flexBasis: "33.33%",
      flexShrink: 0,
      fontWeight: 600,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.text.secondary,
      marginLeft: "1rem",
    },
    details: {
      wordBreak: "break-word",
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        color: "black",
        fontWeight: 400,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 12,
        color: "black",
        fontWeight: 400,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 14,
        color: "black",
        fontWeight: 400,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 16,
        color: "black",
        fontWeight: 400,
      },
    },
  })
);

export default function SpecializationItem() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel0"}
        onChange={handleChange("panel0")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel0bh-content"
          id="panel0bh-header"
        >
          <Typography className={classes.heading}>
            Orofaciale fysiotherapie (OFT)
          </Typography>
          <Typography style={{ marginLeft: "0.1rem" }}>
            <Typography className={classes.details}>
              MSc. Orofaciale fysiothapeut sinds 2024
            </Typography>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      De orofaciaal fysiotherapeut (OFT) is een gespecialiseerde
                      fysiotherapeut bij klachten en beperkingen in het hoofd-
                      en halsgebied en in het kauwstelsel. De orofaciaal
                      fysiotherapeut analyseert, interpreteert en behandelt dit
                      soort gezondheidsproblemen die het gevolg zijn van
                      gewricht-, spier- en zenuwproblemen in de hoofd- en
                      halsregio.
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      <u>Wanneer ga je naar een orofaciaal fysiotherapeut?</u>
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>

            <ListItem
              style={{
                paddingTop: "0",
                paddingBottom: "0",
                marginLeft: "0.5rem",
              }}
            >
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      - Klemmen van de kaak en tandenknarsen (bruxisme)
                    </Typography>
                    <Typography className={classes.details}>
                      - Pijn in het gezicht, kaak of hoofd
                    </Typography>
                    <Typography className={classes.details}>
                      - Oorsuizen (tinnitus)
                    </Typography>
                    <Typography className={classes.details}>
                      - Aangezichtsverlamming (bell’s parese)
                    </Typography>
                    <Typography className={classes.details}>
                      - Aspecifieke aangezichtspijn
                    </Typography>
                    <Typography className={classes.details}>
                      - Duizeligheid en BPPD
                    </Typography>
                    <Typography className={classes.details}>
                      - Nek- en schouderklachten
                    </Typography>
                    <Typography className={classes.details}>
                      - Hoofdpijn en vormen van migraine
                    </Typography>
                    <Typography className={classes.details}>
                      - Gevolgen van ongelukken en botbreuken
                    </Typography>
                    <Typography className={classes.details}>
                      - Klachten in het hoofd-halsgebied door kanker, reuma of
                      een andere ziekte
                    </Typography>
                    <Typography className={classes.details}>
                      - Klachten in het hoofd-halsgebied die te maken hebben met
                      spanning of stress
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      Bron:{" "}
                      <Link
                        href="https://defysiotherapeut.com/gespecialiseerde-fysiotherapie/manueel-therapeut/"
                        onClick={preventDefault}
                      >
                        https://defysiotherapeut.com/gespecialiseerde-fysiotherapie/manueel-therapeut/
                      </Link>
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            Manuele therapie (OMT)
          </Typography>
          <Typography className={classes.secondaryHeading}>
            <Typography className={classes.details}>
              MSc. Manueel therapeut sinds 2013
            </Typography>
            <Typography className={classes.details}>
              OMT / Orthopedische Manuele Therapie, HBO Master Hogeschool
              Utrecht
            </Typography>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      Manueel therapeuten zijn gespecialiseerd in het onderzoek
                      en de behandeling van patiënten met klachten aan de
                      wervelkolom en de gewrichten in armen en benen. Ze kijken
                      naar de manier waarop mensen bewegen en zorgen dat de
                      gewrichten beter werken. Hierdoor bewegen ze (weer) soepel
                      en neemt de pijn af. Manueel therapeuten hebben na een
                      studie fysiotherapie een driejarige vervolgopleiding voor
                      manueel therapeut gevolgd.
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      <u>Klachten die de manueel therapeut behandelt, zijn:</u>
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>

            <ListItem
              style={{
                paddingTop: "0",
                paddingBottom: "0",
                marginLeft: "0.5rem",
              }}
            >
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      - Hoofd- en nekpijn (vaak in combinatie met een stijve
                      bovenrug)
                    </Typography>
                    <Typography className={classes.details}>
                      - Nekpijn (al dan niet uitstralend in de arm)
                    </Typography>
                    <Typography className={classes.details}>
                      - Klachten hoog in de rug met rib- en borstpijn
                    </Typography>
                    <Typography className={classes.details}>
                      - (Lage)rugklachten (ook als ze uitstralen naar het been)
                    </Typography>
                    <Typography className={classes.details}>
                      - Duizeligheid die komt door het bewegen van de nek
                    </Typography>
                    <Typography className={classes.details}>
                      - Kaakklachten (soms in combinatie met nekklachten)
                    </Typography>
                    <Typography className={classes.details}>
                      - Schouderklachten (soms in combinatie met nekklachten)
                    </Typography>
                    <Typography className={classes.details}>
                      - Pijn en stijfheid in de schouder (Frozen shoulder)
                    </Typography>
                    <Typography className={classes.details}>
                      - Tenniselleboog
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      Bron:{" "}
                      <Link
                        href="https://defysiotherapeut.com/gespecialiseerde-fysiotherapie/manueel-therapeut/"
                        onClick={preventDefault}
                      >
                        https://defysiotherapeut.com/gespecialiseerde-fysiotherapie/manueel-therapeut/
                      </Link>
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Dry Needling</Typography>
          <Typography className={classes.secondaryHeading}>
            <Typography className={classes.details}>
              {" "}
              Dry Needling specialist sinds 2010{" "}
            </Typography>
            <Typography className={classes.details}>
              {" "}
              Cursus wervelkolom, ledematen en hoofd/halsregio
            </Typography>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      Dry needling is een behandelmethode uit Canada en wordt al
                      jaren toegepast binnen de reguliere fysiotherapie. Door
                      middel van een dunne (acupunctuur) naald worden spieren
                      aangeprikt en raken op die manier snel en langdurig
                      ontspannen. Bij Dry needling wordt er gebruik gemaakt van
                      een droge (dry) naald. Er wordt dus geen vloeistof in de
                      spier gebracht.
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      <u>Dry needling en acupunctuur</u>
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      Dry needling is niet hetzelfde als acupunctuur. Bij
                      acupunctuur worden er vaak oppervlakkig meerdere naalden
                      in het lichaam gezet. Deze naalden hebben een invloed op
                      de energie van het lichaam. Bij Dry needling wordt er een
                      specifiek punt in de spier aangeprikt (triggerpoint). Een
                      triggerpoint is een verharding in een spier die zowel
                      lokaal als uitstralende klachten kan veroorzaken.
                      Triggerpoints kunnen de volgende klachten veroorzaken;
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      <u>Hoe ontstaan triggerpoints?</u>
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
            <ListItem style={{ paddingTop: "0", marginLeft: "1rem" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      - pijn en stijfheid lokaal of uitstralende pijn
                    </Typography>
                    <Typography className={classes.details}>
                      - bewegingsbeperkingen in gewrichten
                    </Typography>
                    <Typography className={classes.details}>
                      - verminderde kracht in de betrokken spier
                    </Typography>
                    <Typography className={classes.details}>
                      - pijnontwijkend bewegen
                    </Typography>
                    <Typography className={classes.details}>
                      - tintelend gevoel in de armen/benen
                    </Typography>
                    <Typography className={classes.details}>
                      - hoofdpijnklachten en duizeligheid
                    </Typography>
                    <Typography className={classes.details}>
                      - Acuut moment bv door een verkeerde beweging (vertillen)
                      of een ongeval of sportletsel
                    </Typography>
                    <Typography className={classes.details}>
                      - chronisch bv door een langdurige verkeerde houding
                    </Typography>
                    <Typography className={classes.details}>
                      - immobiliteit bv agv gips, brace of een sling
                    </Typography>
                    <Typography className={classes.details}>
                      - instabiliteit van gewrichten
                    </Typography>
                    <Typography className={classes.details}>
                      - stressfactoren
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>

            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      <u>Wat voelt u van Dry needling</u>
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      Het inbrengen van het naaldje voelt u in principe niet.
                      Als de triggerpoint wordt aangeprikt spant de spier zich
                      even aan. Dit kan even een soort kramp gevoel geven.
                      Daarna kunnen de spieren tijdelijk wat zwaar en vermoeid
                      aanvoelen (spierpijngevoel).
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className={classes.accordion}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Mckenzie Methode</Typography>
          <Typography className={classes.secondaryHeading}>
            <Typography className={classes.details}>
              {" "}
              A cursus - lumbale wervelkolom{" "}
            </Typography>
            <Typography className={classes.details}>
              {" "}
              B cursus - cervicale en thoracale wervelkolom
            </Typography>
            <Typography className={classes.details}>
              {" "}
              C cursus - Gevorderde lumbale wervelkolom en onderste extremiteit
            </Typography>
            <Typography className={classes.details}>
              {" "}
              D cursus - Gevorderde cervicale wervelkolom en bovenste
              extremiteit
            </Typography>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      De Mckenzie Methode is een internationale methode voor
                      onderzoek en behandeling van pijn aan de wervelkolom en
                      ledematen. Het is ontwikkeld door fysiotherapeut Robin
                      Mckenzie uit Nieuw Zeeland. Deze methode wordt al meer dan
                      30 jaar wereldwijd gebruikt.
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      Als u een van de volgende klachten heeft kan de Mckenzie
                      methode voor u geschikt zijn:
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
            <ListItem
              style={{
                paddingTop: "0",
                paddingBottom: "0",
                marginLeft: "1rem",
              }}
            >
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      - Ischias (Uitstralende pijn vanuit de rug naar één of
                      beide benen)
                    </Typography>
                    <Typography className={classes.details}>
                      - Gewrichtspijn in de heupen of billen, schouders of
                      bovenarmen
                    </Typography>
                    <Typography className={classes.details}>
                      - Moeite met buigen als gevolg van stijfheid of pijn in de
                      onderrug
                    </Typography>
                    <Typography className={classes.details}>
                      - Pijn die begint in de rug of de nek en lijkt te
                      verspreiden naar de billen of schouders
                    </Typography>
                    <Typography className={classes.details}>
                      - Wisselende tintelingen of gevoelloosheid in de voeten of
                      handen
                    </Typography>
                    <Typography className={classes.details}>
                      - Zeurende pijn in de ellebogen of knieën, die niet
                      verbeteren door een behandeling van deze gewrichten
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
            <ListItem style={{ paddingBottom: "0", margin: "0" }}>
              <ListItemText
                style={{ color: "white" }}
                secondary={
                  <React.Fragment>
                    <Typography className={classes.details}>
                      Voor meer informatie:{" "}
                      <Link
                        href="https://be.mckenzieinstitute.org/patienten/"
                        onClick={preventDefault}
                      >
                        https://be.mckenzieinstitute.org/patienten/
                      </Link>
                    </Typography>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
