import {
  CssBaseline,
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import NavigationBar from "../components/NavigationBar";
import "../stylesheets/App.css";
import Copyright from "./Copyright";
import AlgemeneInformatieItems from "../components/AlgemeneInformatieItems";
import TopBar from "../components/TopBar";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tarieven from "./Tarieven";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    items: {
      paddingTop: "1rem",
    },
    accordion: {
      width: "100% !important",
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      flexBasis: "33.33%",
      flexShrink: 0,
      fontWeight: 600,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.text.secondary,
      marginLeft: "1rem",
    },
    details: {
      wordBreak: "break-word",
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        color: "black",
        fontWeight: 400,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 12,
        color: "black",
        fontWeight: 400,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 14,
        color: "black",
        fontWeight: 400,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 16,
        color: "black",
        fontWeight: 400,
      },
    },
  })
);

function AlgemeneInformatie() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div style={{ background: "white !important" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          BOOST Fysiotherapie / Algemene informatie / Manuele therapie / Dry
          needling
        </title>
        <meta
          name="description"
          content="BOOST Fysiotherapie algemene informatie betreft de werkwijze, de praktijk regels en het privacybeleid."
        />
        <meta
          property="og:title"
          content="BOOST Fysiotherapie / Algemene informatie / Manuele therapie / Dry needling"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/algemeneinformatie" />
      </Helmet>

      <CssBaseline />
      <TopBar />
      <NavigationBar />

      <Container style={{ height: "100%" }}>
        <Grid
          container
          justifyContent="flex-start"
          className={classes.items}
          direction="row"
        >
          <Accordion
            className={classes.accordion}
            expanded={expanded === "panel0"}
            onChange={handleChange("panel0")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel0bh-content"
              id="panel0bh-header"
            >
              <Typography className={classes.heading}>
                Praktijkinformatie
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AlgemeneInformatieItems />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className={classes.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>Tarieven</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <Tarieven />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Container>
      <Grid
        container
        justifyContent="center"
        style={{
          background: "black",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Copyright />
      </Grid>
    </div>
  );
}

export default AlgemeneInformatie;
