import React from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InfoIcon from "@material-ui/icons/Info";
import MenuIcon from "@material-ui/icons/Menu";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import {
  withStyles,
  Menu,
  MenuItem,
  MenuProps,
  IconButton,
  Link,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTasks,
  faCalendarAlt,
  faBriefcaseMedical,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const StyledMenu = withStyles({
  paper: {
    border: "2px solid #fffdfe",
    background: "#fffdfe",
    borderRadius: "0.3rem",
    top: "85px!important",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:active": {
      background: "rgba(212,239,230,0.61)",
    },
    "&:hover": {
      background: "rgba(212,239,230,0.61)",
    },
  },
}))(MenuItem);

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleClick}
      >
        <MenuIcon fontSize="large" style={{ color: "#4b0340" }} />
      </IconButton>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link
          href="/specialisaties"
          style={{
            textDecorationColor: "rgba(46, 119, 113, 1)",
            width: "100%",
          }}
        >
          <StyledMenuItem>
            <ListItemIcon style={{ alignItems: "center" }}>
              <FontAwesomeIcon
                icon={faBriefcaseMedical as IconProp}
                style={{
                  fontSize: 18,
                  margin: "0",
                  padding: "0",
                  color: "#4b0340",
                }}
              />
              <ListItemText>
                <strong
                  style={{
                    fontSize: 16,
                    color: "#06332e",
                    marginLeft: "1.2rem",
                  }}
                >
                  Specialisaties
                </strong>
              </ListItemText>
            </ListItemIcon>
          </StyledMenuItem>
        </Link>

        <Link
          href="/algemeneinformatie"
          style={{
            textDecorationColor: "rgba(46, 119, 113, 1)",
            width: "100%",
          }}
        >
          <StyledMenuItem>
            <ListItemIcon style={{ alignItems: "center" }}>
              <FontAwesomeIcon
                icon={faTasks as IconProp}
                style={{
                  fontSize: 18,
                  margin: "0",
                  padding: "0",
                  color: "#4b0340",
                }}
              />
              <ListItemText>
                <strong
                  style={{
                    fontSize: 16,
                    color: "#06332e",
                    marginLeft: "1.2rem",
                  }}
                >
                  Informatie
                </strong>
              </ListItemText>
            </ListItemIcon>
          </StyledMenuItem>
        </Link>

        <Link
          href="/praktijk"
          style={{
            textDecorationColor: "rgb(133, 23, 157)",
            width: "100%",
          }}
        >
          <StyledMenuItem>
            <ListItemIcon style={{ alignItems: "center" }}>
              <InfoIcon
                style={{
                  fontSize: 18,
                  margin: "0",
                  padding: "0",
                  color: "#4b0340",
                }}
              />
              <ListItemText>
                <strong
                  style={{
                    fontSize: 16,
                    color: "#06332e",
                    marginLeft: "1.2rem",
                  }}
                >
                  Over de therapeut
                </strong>
              </ListItemText>
            </ListItemIcon>
          </StyledMenuItem>
        </Link>

        <Link
          href="/contact"
          style={{
            textDecorationColor: "rgba(46, 119, 113, 1)",
            width: "100%",
          }}
        >
          <StyledMenuItem>
            <ListItemIcon style={{ alignItems: "center" }}>
              <ContactMailIcon
                style={{
                  fontSize: 18,
                  margin: "0",
                  padding: "0",
                  color: "#4b0340",
                }}
              />
              <ListItemText>
                <strong
                  style={{
                    fontSize: 16,
                    color: "#06332e",
                    marginLeft: "1.2rem",
                  }}
                >
                  Contact
                </strong>
              </ListItemText>
            </ListItemIcon>
          </StyledMenuItem>
        </Link>

        <Link
          href="/afspraken"
          style={{
            textDecorationColor: "rgba(46, 119, 113, 1)",
            width: "100%",
          }}
        >
          <StyledMenuItem>
            <ListItemIcon style={{ alignItems: "center" }}>
              <FontAwesomeIcon
                icon={faCalendarAlt as IconProp}
                style={{
                  fontSize: 18,
                  margin: "0",
                  padding: "0",
                  color: "rgb(133, 23, 157)",
                }}
              />
              <ListItemText>
                <strong
                  style={{
                    fontSize: 16,
                    color: "#06332e",
                    marginLeft: "1.2rem",
                  }}
                >
                  Afspraak maken
                </strong>
              </ListItemText>
            </ListItemIcon>
          </StyledMenuItem>
        </Link>
      </StyledMenu>
    </div>
  );
}
