import { Container, CssBaseline, Grid, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import SpecializationItem from "../components/SpecializationItem";
import "../stylesheets/App.css";
import Copyright from "./Copyright";
import NavigationBar from "../components/NavigationBar";
import TopBar from "../components/TopBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    items: {
      paddingTop: "1rem",
    },
    title: {
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        marginLeft: "1rem",
        textAlign: "center",
        fontSize: 14,
        fontWeight: 700,
        color: "rgba(46, 119, 113, 1)",
      },
      [theme.breakpoints.up("sm")]: {
        justifyContent: "center",
        marginLeft: "1rem",
        textAlign: "center",
        fontSize: 18,
        fontWeight: 700,
        color: "rgba(46, 119, 113, 1)",
      },
      [theme.breakpoints.up("md")]: {
        justifyContent: "left",
        marginLeft: "1rem",
        textAlign: "left",
        fontSize: 18,
        fontWeight: 700,
        color: "rgba(46, 119, 113, 1)",
      },
      [theme.breakpoints.up("lg")]: {
        justifyContent: "left",
        marginLeft: "1rem",
        textAlign: "left",
        fontSize: 22,
        fontWeight: 700,
        color: "rgba(46, 119, 113, 1)",
      },
    },
    details: {
      wordBreak: "break-all",
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        color: "black",
        fontWeight: 400,
        margin: 0,
        padding: 0,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 12,
        color: "black",
        fontWeight: 400,
        margin: 0,
        padding: 0,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 14,
        color: "black",
        fontWeight: 400,
        margin: 0,
        padding: 0,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 16,
        margin: 0,
        color: "black",
        fontWeight: 400,
        padding: 0,
      },
    },
  })
);

function Specialisaties() {
  const classes = useStyles();

  return (
    <div style={{ background: "white !important" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          BOOST Fysiotherapie / Manuele therapie / Dry needling / Medical taping
          / Mckenzie Methode
        </title>
        <meta
          name="description"
          content="BOOST fysiotherapie is een specialistische praktijk voor fysio en manuele therapie in Nieuw Vennep. Gespecialiseerd in rugklachten, rugpijn, nekklachten, nekpijn, hoofdpijn, duizeligheid (BPPD) en schouderklachten."
        />
        <meta
          property="og:title"
          content="BOOST Fysiotherapie / Manuele therapie / Dry needling / Medical taping / Mckenzie Methode"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/specialisaties" />
      </Helmet>

      <CssBaseline />

      <TopBar />
      <NavigationBar />

      <Container style={{ height: "100%" }}>
        <Grid
          container
          justifyContent="flex-start"
          className={classes.items}
          direction="row"
        >
          <Typography
            component="h1"
            color="textPrimary"
            align="left"
            className={classes.title}
          >
            Specialisaties
          </Typography>
          <SpecializationItem />
        </Grid>
      </Container>
      <Grid
        container
        justifyContent="center"
        style={{
          background: "black",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Copyright />
      </Grid>
    </div>
  );
}

export default Specialisaties;
