import ListItemText from "@material-ui/core/ListItemText";
import { Link, Grid, makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexWrap: "nowrap",
      alignItems: "center !important",
    },
    font: {
      color: "#4b0340",
      marginLeft: "0.5rem",
      display: "flex",
      fontWeight: 700,
      marginTop: "auto",
      marginBottom: "auto",
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 15,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 18,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 18,
      },
    },
  })
);

export default function LinearNavContent() {
  const classes = useStyles();
  return (
    <div>
      <Grid
        container
        justifyContent="space-around"
        direction="row"
        className={classes.root}
      >
        <Link
          href="/specialisaties"
          style={{
            textDecorationColor: "rgb(133, 23, 157)",
          }}
        >
          <ListItemText>
            <strong className={classes.font}>Specialisaties</strong>
          </ListItemText>
        </Link>

        <Link
          href="/algemeneinformatie"
          style={{
            textDecorationColor: "rgb(133, 23, 157)",
          }}
        >
          <ListItemText>
            <strong className={classes.font}>Informatie</strong>
          </ListItemText>
        </Link>

        <Link
          href="/praktijk"
          style={{
            textDecorationColor: "rgb(133, 23, 157)",
          }}
        >
          <ListItemText>
            <strong className={classes.font}>Over de therapeut</strong>
          </ListItemText>
        </Link>

        <Link
          href="/contact"
          style={{
            textDecorationColor: "rgb(133, 23, 157)",
          }}
        >
          <ListItemText>
            <strong className={classes.font}>Contact</strong>
          </ListItemText>
        </Link>
      </Grid>
    </div>
  );
}
